import React, {useState} from "react";
import cl from './MobilMenu.module.css'
import { RiMenu4Fill, RiCloseFill } from "react-icons/ri";
import Navigation from "../navigation/Navigation";

const MobilMenu = () => {
const [isOpen, setIsOpen] = useState(false)

    return(
        <aside className={`${cl.sidebar}`}>
            <button
                type='button'
                onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? <RiCloseFill className={`${cl.open} ${cl.sidebar__svg}`}/> : <RiMenu4Fill className={cl.sidebar__svg} />}
                </button>
                <div className={`${cl.sidebar__nav} ${isOpen ? cl.active : cl.deactive }`}>
                    <div className={cl.sidebar__nav__list}>
                        <div className={cl.sidebar__nav__list__item}>
                            <Navigation />
                        </div>
                    </div>
                </div>
        </aside>
    )
}

export default MobilMenu
