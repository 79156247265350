import React from "react";
import cl from './Navigation.module.css'


const Navigation = () => {
    return(
        <nav className={cl.nav}>
            <ul className={cl.nav__list}>
                <a href="#about">
                    <div className={cl.count}>
                        <div className={cl.wrapper}>
                            <li className={`${cl.link} ${cl.hover}`}>О студии</li>
                        </div>
                    </div>
                </a>
                <a href="#services">
                    <div className={cl.count}>
                        <div className={cl.wrapper}>
                            <li className={`${cl.link} ${cl.hover}`}>Услуги</li>
                        </div>
                    </div>
                </a>
                <a href='#partners'>
                    <div className={cl.count}>
                        <div className={cl.wrapper}>
                            <li className={`${cl.link} ${cl.hover}`}>Партнеры</li>
                        </div>
                    </div>
                </a>
                <a href="#contact">
                    <div className={cl.count}>
                        <div className={cl.wrapper}>
                            <li className={`${cl.link} ${cl.hover}`}>Контакты</li>
                        </div>
                    </div>
                </a>
            </ul>
        </nav>
    )
}
export default Navigation