import React, { useState, useEffect, useRef } from 'react'
import cl from './AboutUs.module.css'
import { motion } from 'framer-motion'
import Logotype from '../../modules/logotype/Logotype';
import Triangle from '../../modules/triangle/Triangle';
import { SiExpress, SiStrapi, SiReact, SiNodedotjs, SiPython, SiHtml5, SiCss3, SiSass, SiJquery, SiJavascript, SiDjango } from "react-icons/si";
import StaggerText from 'react-stagger-text'



const AboutUs = () => {

  const [textIsVisible, setTextIsVisible] = useState(false)
  const [ iconIsVisible, setIconIsVisible] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTextIsVisible(true)
    }, 1500)

    return() => clearTimeout(timeout)
  }, [])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIconIsVisible(true)
    }, 9000)

    return() => clearTimeout(timeout)
  }, [])



    return (
      <section className={cl.about} name='about' id='about'>
        <div className={cl.about__left}>
          <Logotype h2Text={'о студии'} h3Text={'немного'}/>
          <div className={cl.about__right__triangle}>
            <Triangle />
        </div>
        </div>
        <div className={cl.about__right}>
          <div className={`${cl.about__right__block}`}>
            { textIsVisible && (
              <motion.div
                initial={{ opacity: 0}}
                animate={{opacity: 1}}
                transition={{ duration: 1}}
              >
                <div className={`${cl.card} box`}>
                  <p>
                    <StaggerText
                      staggerDuration={1.5}
                    >
                    Занимаемся созданием веб-сайтов с 2022 года, успели набрать опыт в создании различных веб-проектов. Нашим главным принципом является индивидуальный подход к каждому клиенту и стремление к качественному результату.
                    </StaggerText>
                  </p>
                  <p>
                    <StaggerText
                      staggerDuration={1.5}
                      startDelay={2000}
                    >
                    Вы получаете гарантию того, что ваш проект будет выполнен в срок и в соответствии с Вашими ожиданиями. Предлагаем прозрачное сотрудничество и готовность учесть все Ваши пожелания и требования. Будучи ответственными исполнителями, сосредоточены на Вашем проекте и готовы предоставить Вам наши профессиональные навыки и опыт.
                    </StaggerText>
                  </p>
                  <p>
                    <StaggerText
                      staggerDuration={1.5}
                      startDelay={4000}
                    >
                    Использование передовых технологий и лучших практик веб-разработки. Следим за последними тенденциями отрасли и постоянно совершенствует свои навыки, чтобы предоставить клиентам сайт высочайшего качества. Доверьте создание своего веб-присутствия нам, и сделаем все возможное, чтобы ваш бизнес процветал в онлайн-мире.
                    </StaggerText>
                  </p>
                </div> 
              </motion.div>
            )} 
            { iconIsVisible && (
              <motion.div
                initial={{ opacity: 0}}
                animate={{opacity: 1}}
                transition={{ duration: 1}}
                delay={4}
              >
                <div className={cl.about__right__block__icon}>
                  <SiCss3 title="css" />
                  <SiHtml5 title="html" />
                  <SiJavascript  title="JavaScript" />
                  <SiPython title="Python" />
                  <SiSass title="Sass" />
                  <SiNodedotjs title="Node.js" />
                  <SiJquery title="JQuery" />
                  <SiReact title="React" />
                  <SiExpress title="Express.js" />
                  <SiStrapi title="Strapi" />
                  <SiDjango title="Django" />
                </div>
              </motion.div>
            )} 
            
          
          </div>
        </div>
      </section>
    )
}

export default AboutUs
