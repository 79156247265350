import React, { useState, useEffect } from 'react'
import Triangle from '../../modules/triangle/Triangle'
import Logotype from '../../modules/logotype/Logotype'
import cl from './Services.module.css'
import { motion, AnimatePresence } from 'framer-motion'
import list from '../../API/services/data'
import CustomModal from '../../modules/modal/CustomModal'

const Services = () => {


  const [selectedTab, setSelectedTab] = useState(list[0])
  const [isOpenModal, setIsOpenModal] = useState(false)

    return (
      <section name='services' id='services' className={cl.services}>
        <div className={cl.services__top}>
            <div className={cl.services__top__logo}>
              <Logotype h2Text={ 'об услугах' } h3Text={ 'Немного' }/>
            </div>
            <div className={cl.services__triangle}>
                <Triangle />
            </div>
        </div>
        <div className={cl.services__bottom}>
          <div className={cl.services__bottom__list}>
            <ul>
            {list.map((item) => (
                <li
                  key={item.label}
                  className={item.label === selectedTab.label ? `${cl.selected}` : ""}
                  onClick={() => setSelectedTab(item, console.log(item))}>
                  {item.label}
                </li>
              ))}
            </ul>
            
            <h3>Стремимся к тому, чтобы каждый проект был уникальным и максимально эффективным для вашего бизнеса. Работаем с каждым клиентом индивидуально, учитывая все его требования и пожелания.</h3>
          </div>
          <AnimatePresence mode='wait'>
              <motion.div
                key={selectedTab.label}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className={cl.services__bottom__text}
              >
                {selectedTab.description}
              </motion.div>
            </AnimatePresence>
            <button onClick={() => setIsOpenModal(true)} className={cl.modal_btn}>Рассчитать стоимость<br /> {selectedTab.children}</button>
        </div>
        <CustomModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
        />
      </section>
    )
}

export default Services