import React from 'react'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Logotype from '../../modules/logotype/Logotype'
import cl from './Contact.module.css'

const Contact = () => {

    const currentYear = new Date().getFullYear();

    return(
        <section id='contact' name='contact' className={cl.contact}>
            <div className={cl.contact__left}>
                
            </div>
            <div className={cl.contact__right}>
                <div className={cl.contact__map}>
                    <YMaps>
                        <Map defaultState={{
                                center: [45.122023, 39.007976],
                                zoom: 13,
                                controls: ["zoomControl", "fullscreenControl"],
                            }}
                            modules={["control.ZoomControl", "control.FullscreenControl"]}
                            width='100%'
                            height={400}
                        >
                            <Placemark defaultGeometry={[45.122023, 39.007976]} />
                        </Map>
                        
                    </YMaps>
                    <h3>b_root</h3>
                    <h4>Студия веб разработки</h4>
                </div>
                <div className={cl.contact__right__bottom}>
                    <div className={cl.contact__right__bottom__text}>
                        <div className={cl.square}>
                            <div className={cl.logo}>
                                <Logotype h2Text={'b_root'} h3Text={'Студия'} />
                            </div>
                        </div>
                        <h5>Наш адрес:</h5>
                        <p>350000 г.Краснодар</p>
                        <p>ул. Кадетская, д.104</p>
                        <h6>телефон для связи:</h6>
                        <a href="tel:+79034584095">+7(903)458-40-95</a>
                        <h6>e-mail:</h6>
                        <a href="mailto:info@build-root.ru">info@build-root.ru</a>
                        <br />
                        <br />
                        <p>Лизун Юрий Станиславович</p>
                        <p>ИНН 233907125450</p>
                        <p>&copy; 2022-{currentYear}</p>
                        
                    </div>

                </div>
            </div>
        </section>

    )
}

export default Contact