import React from 'react'
import ScrollToTop from '../../UI/scrollToTop/ScrollToTop'
import HomePage from "../../screens/homePage/HomePage";
import AboutUs from "../../screens/about/AboutUs";
import Services from "../../screens/services/Services";
import Form from "../../screens/form/Form";
import Parnters from "../../screens/partners/Parnters";
import Contact from "../../screens/contact/Contact";
import Footer from '../../modules/footer/Footer';
import CookieNotification from '../../modules/cookieNotification/CookieNotification'


const IndexPage = () => {

    return (
      <>
        <HomePage />
        <AboutUs />
        <Services />
        <Parnters/>
        <Form />
        <Contact />
        <Footer />
        <CookieNotification />
        <ScrollToTop />
      </>
    )

}

export default IndexPage