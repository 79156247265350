import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IndexPage from "./components/pages/indexPage/IndexPage";
import Cookie from './components/pages/cookie/Cookie';
import Usage from './components/pages/usage/Usage'
import Confidentiality from './components/pages/confidentiality/Confidentiality'
import Page404 from './components/pages/404/Page404'






function App() {


  return (
    <BrowserRouter> 
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/usage" element={<Usage />} />
        <Route path="/confidentiality" element={<Confidentiality />} />
        <Route path="/cookie" element={<Cookie />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
