import React, { useState, useEffect } from 'react';
import Logotype from '../../modules/logotype/Logotype';
import cl from './Partners.module.css';

const Parnters = () => {


    return (
        <section name='partners' id='partners' className={cl.partners}>
            <div className={cl.partners__top}>
                <div className={cl.partners__top__logo}>
                    <Logotype h3Text={'присоединяйтесь'} h2Text={'к нам'} />
                </div>
            </div>
            <div className={cl.partners__block}>
                <div className={cl.partners__logo}>
                    <a href='https://fontan34.ru/'>
                        <img src='/image/partners/atpfnt.svg' alt="АкваТехПласт" />
                        <h5>АкваТехПласт</h5>
                        <h6>Строительство фонтанов</h6>
                        <p>Волгоград</p>
                    </a>
                </div>
                <div className={cl.partners__logo}>
                    <a href='https://sovahair.ru/'>
                        <img src='/image/partners/sova.png' alt="Салон красоты сова" />
                        <h5>Сова</h5>
                        <h6>Салон красоты</h6>
                        <p>Краснодар</p>
                    </a>
                </div>
                <div className={cl.partners__logo}>
                    <a href='https://poolkuban.ru/'>
                        <img src='/image/partners/atp.png' alt="АкваТехПласт" />
                        <h5>АкваТехПласт</h5>
                        <h6>Строительство бассейнов</h6>
                        <p>Краснодар</p>
                    </a>
                </div>
                <div className={cl.partners__logo}>
                    <a href='https://akvaplast34.ru/'>
                        <img src='/image/partners/kron.png' alt="Кронстрой" />
                        <h5>Кронстрой</h5>
                        <h6>Емкости и резервуары</h6>
                        <p>Волгоград</p>
                    </a>
                </div>
                <div className={cl.partners__logo}>
                    <a href='https://monolit-kuban.ru/'>
                        <img src='/image/partners/bet.png' alt="Бетон кубани" />
                        <h5>Монолит бетон</h5>
                        <h6>Бетонные работы</h6>
                        <p>Краснодар</p>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Parnters;
