import React, { Component } from 'react'
import { Link } from 'react-router-dom';

const LinkToHome = ({textLink}) => {

    return (
      <Link to='/'>{textLink}</Link>
    )

}

export default LinkToHome