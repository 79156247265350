import React from 'react'
import cl from './Footer.module.css'
import Social from '../../UI/social/Social'
import { Link } from 'react-router-dom';

const Footer = () => {

  const currentYear = new Date().getFullYear();

    return (
      <footer className={cl.footer}>
        <div className={cl.footer__left}>
          <div className={cl.footer__left__square}></div>
          <div className={cl.footer__left__square}></div>
        </div>
        <div className={cl.footer__right}>
          <h6>Мы в социальных сетях:</h6>
          <Social />
          <div className={cl.footer__right__bottom}>
            <div className={cl.footer__right__bottom__left}>
            </div>
            <div className={cl.footer__right__bottom__right}>
              <ul className={cl.nav__list}>
                  <a href="#about">
                      <div className={cl.count}>
                          <div className={cl.wrapper}>
                              <li className={`${cl.link} ${cl.hover}`}>О студии</li>
                          </div>
                      </div>
                  </a>
                  <a href="#services">
                      <div className={cl.count}>
                          <div className={cl.wrapper}>
                              <li className={`${cl.link} ${cl.hover}`}>Услуги</li>
                          </div>
                      </div>
                  </a>
                  <a href='#partners'>
                      <div className={cl.count}>
                          <div className={cl.wrapper}>
                              <li className={`${cl.link} ${cl.hover}`}>Партнеры</li>
                          </div>
                      </div>
                  </a>
                  <a href="#contact">
                      <div className={cl.count}>
                          <div className={cl.wrapper}>
                              <li className={`${cl.link} ${cl.hover}`}>Контакты</li>
                          </div>
                      </div>
                  </a>
              </ul>
              <div className={cl.footer__right__bottom__right__link}>
                <Link to='/confidentiality'>Политика конфиденциальности</Link>
                <Link to='/usage'>Условия использования</Link>
                <Link to='/cookie'>Политика хранения<br /> и обработки файлов cookie</Link>
                <a href="https://build-root.ru">&copy; { currentYear} b_root</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )

}

export default Footer
