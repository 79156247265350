import React from 'react';
import CookieConsent from 'react-cookie-consent';
import cl from './CoockieNotification.module.css'
import { Link } from 'react-router-dom';

const CookieNotification = () => {
  return (
    <CookieConsent
        location="bottom"
        buttonText="Принять"
        cookieName="myCookieConsent"
        style={{ background: 'rgba(43, 55, 59, 0.6)',
                textAlign: 'right'}}
        buttonStyle={{ background: 'rgb(253, 253, 253)', 
                    fontSize: '16px',
                    color: 'rgb(0, 0, 0)',
                    fontWeight : '900' }}
    >
      <div className={cl.block}>
            <h6>Продолжая пользоваться сайтом Вы даете согласие 
                на обработку и хранение файлов cookie 
                в соотвествии с<Link to='/cookie'> правилами </Link> </h6>
      </div>
    </CookieConsent>
  );
};

export default CookieNotification;
