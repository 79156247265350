import React, { Component } from 'react'
import Logotype from '../../modules/logotype/Logotype'
import cl from './Form.module.css'
import CustomForm from '../../UI/form/CustomForm'
import '../../modules/triangle/Triangle'

const Form = () => {

    return (
      <section name='form' id='form' className={cl.form}>
            <div className={cl.form__bottom}>
               <CustomForm titleForm={'Давайте знакомиться'}/> 
            </div>
      </section>
    )

}

export default Form