import React from 'react'
import cl from '../confidentiality/ListStyle.module.css'
import LinkToHome from '../../UI/linkToHome/LinkToHome';

const Cookie = () => {

    return (
      <div className={` container ${cl.base}`}>
        <h1>правила сбора и хранения файлов cookie</h1>
        <LinkToHome textLink={'Вернуться на сайт'} />
        <h5>1. Общие положения</h5>
        <ol>
            <li>Настоящие Правила сбора и хранения файлов cookie (далее - "Правила") разработаны в соответствии с требованиями Федерального закона от 27.07.2006 №152-ФЗ "О персональных данных" и определяют порядок обработки файлов cookie и меры по обеспечению безопасности персональных данных, которые получает и обрабатывает Лизун Юрий Станиславович (далее - "Оператор")</li>
            <li>Правила действуют в отношении всей информации, которую Оператор может получить о Пользователе во время использования им сайта https://build-root.ru/ (далее - "Сайт").</li>
            <li>Использование Сайта Пользователем означает согласие с настоящими Правилами и условиями обработки персональных данных Пользователя.</li>
            <li>В случае несогласия с условиями Правил Пользователь должен прекратить использование Сайта.</li>
            <li>Оператор вправе вносить изменения в Правила. Новая редакция Правил вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Правил.</li>
        </ol>
        <h5>2. Предмет Правил сбора и хранения файлов cookie</h5>
        <ol>
            <li>Настоящие Правила устанавливают обязательства Оператора по неразглашению и обеспечению режима защиты конфиденциальности файлов cookie, которые Пользователь предоставляет при использовании Сайта.</li>
            <li>Файлы cookie, разрешённые к обработке в рамках настоящих Правил, предоставляются Пользователем путём заполнения формы обратной связи на Сайте и могут включать в себя следующую информацию:
                <ul>
                    <li>IP-адрес Пользователя;</li>
                    <li> Данные о браузере Пользователя;</li>
                    <li>Данные о времени посещения Сайта;</li>
                    <li>Данные о страницах Сайта, которые посетил Пользователь;</li>
                    <li>Иную информацию, которую Пользователь добровольно предоставляет.</li>
                </ul>
            </li>
        </ol>
        <h5>3. Цели сбора файлов cookie</h5>
        <ol>
            <li>Оператор собирает и хранит только те файлы cookie, которые необходимы для предоставления услуг Пользователю и для исполнения соглашений и договоров с Пользователем.</li>
            <li>Файлы cookie Пользователя Оператор может использовать в следующих целях:
                <ul>
                    <li>Идентификации Пользователя, зарегистрированного на Сайте.</li>
                    <li>Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</li>
                    <li>Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработки запросов и заявок от Пользователя.</li>
                    <li>Уведомления Пользователя Сайта о новостях и изменениях в работе Сайта.</li>
                    <li> Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.</li>
                    <li>Осуществления рекламной деятельности с согласия Пользователя.</li>
                </ul>
            </li>
        </ol>
        <h5>4. Способы и сроки обработки файлов cookie</h5>
        <ol>
            <li>Обработка файлов cookie Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</li>
            <li>Пользователь соглашается с тем, что Оператор вправе передавать файлы cookie третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно для целей выполнения заказа Пользователя, оформленного на Сайте.</li>
            <li>Файлы cookie Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</li>
            <li>При утрате или разглашении файлов cookie Оператор информирует Пользователя об утрате или разглашении файлов cookie.</li>
            <li>Оператор принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</li>
            <li>Оператор совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением файлов cookie Пользователя.</li>
        </ol>
        <h5>5. Права и обязанности сторон</h5>
        <ol>
            <li>Пользователь вправе:
                <ul>
                    <li>Принимать свободное решение о предоставлении своих файлов cookie, необходимых для использования Сайта, и давать согласие на их обработку.</li>
                    <li>Обновить, дополнить предоставленную информацию о файлах cookie в случае изменения данной информации.</li>
                </ul>
            </li>
            <li>Оператор обязан:
                <ul>
                    <li>Использовать полученную информацию исключительно для целей, указанных в п. 3 настоящих Правил сбора и хранения файлов cookie.</li>
                    <li>Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных файлов cookie Пользователя, за исключением п.п. 4.2 и 4.3 настоящих Правил.</li>
                    <li>Принимать меры предосторожности для защиты конфиденциальности файлов cookie Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</li>
                    <li>Осуществить блокирование файлов cookie, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных файлов cookie или неправомерных действий.</li>
                </ul>
            </li>
        </ol>
        <h5>6. Ответственность сторон</h5>
        <ol>
            <li>Оператор не несет ответственности за возможные убытки, которые могут возникнуть в результате использования Сайта.</li>
            <li>Пользователь несет ответственность за нарушение настоящих Условий и возможные последствия такого нарушения.</li>
            <li>В случае нарушения настоящих Условий Оператор имеет право ограничить доступ Пользователя к Сайту.</li>
        </ol>
        <h5>7. Заключительные положения</h5>
        <ol>
            <li>Правила сбора и хранения файлов cookie являются неотъемлемой частью Политики конфиденциальности.</li>
            <li>Оператор вправе вносить изменения в Правила сбора и хранения файлов cookie. Новая редакция Правил сбора и хранения файлов cookie вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Правил сбора и хранения файлов cookie.</li>
            <li> Все споры между сторонами разрешаются путем переговоров. При невозможности достижения согласия спор передается на рассмотрение в судебные органы в соответствии с действующим законодательством Российской Федерации.</li>
            <li> К настоящим Правилам и отношениям между Пользователем и Оператором применяется действующее законодательство Российской Федерации.</li>
        </ol>
        <LinkToHome textLink={'Вернуться на сайт'} />
      </div>
    )
}

export default Cookie