import React from 'react'
import cl from './Logotype.module.css'

const Logotype = ({h2Text, h3Text}) => {
    return (
        <div className={cl.box}>
        <div className={cl.box__border}></div>
        <div className={cl.box__text}>
          <h3>{h3Text}</h3>
          <h2>{ h2Text }</h2>
        </div>
        </div>
    )

}

export default Logotype