import React from 'react'
import cl from './Triangle.module.css'

const Triangle = () => {

    return (
      <div className={cl.triangle}>
        <div className={cl.triangle__one}>
        </div>
        <div className={cl.triangle__two}></div>
        <div className={cl.triangle__there}></div>
      </div>
    )
}

export default Triangle