import React, { useEffect, useState } from 'react'
import cl from './HomePage.module.css'
import Header from '../../modules/header/Header'
import Social from '../../UI/social/Social'

const HomePage = () => {
  const title = [
    '   b_root  ',
    'Студия веб разрабортки',
    'web development studio'
  ]
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex +1 ) % title.length);
    }, 2000)
    return () => clearInterval(interval)
  }, [])
  
  useEffect(() => {
    document.title = title[currentIndex];
  }, [currentIndex, title]);



    return (
      <section>
        
        <div className={`${cl.main}`}>
          <div className={cl.main__left}>
            <div className={cl.circle}>
              <div className={cl.circle__mini}>
                <div className={cl.logo}></div>
              </div>
            </div>
            
          </div>
          <div className={cl.main__right}>
            <Header />
            <a href="https://build-root.ru">
              <div className={cl.main__right__title}>
                <div className={cl.main__right__title__text}>
                  <h1>Студия веб разработки</h1>
                </div>
                <div className={cl.main__right__title__logo}>
                  <h2>b_root</h2>
                  <p>est. 2022<sup> &copy;</sup></p>
                </div>
              </div>
            </a>
            <Social />
          </div>
        </div>
      </section>
    )

}

export default HomePage
